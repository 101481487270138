import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { Colors } from '../../styles/colors'
import { Spacings } from '../../styles/spacings'

const StyledPaper = styled.div`
  background-color: ${props => props.backgroundColor ?? Colors.background};
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  padding: ${Spacings['42']};
`

export const Paper = ({ children, backgroundColor }) => {
  return <StyledPaper backgroundColor={backgroundColor}>{children}</StyledPaper>
}

Paper.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string,
}
