import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { Header } from '../../atoms/Typography/Header'
import { Text } from '../../atoms/Typography/Text'
import Image from '../../components/ImageByPath'
import { Button } from '../../molecules/Button/Button'
import { Breakpoints } from '../../styles/breakpoints'
import { Bold } from '../../styles/common'

const Layout = styled.div`
  max-height: inherit;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto;
  grid-template-areas: ${props =>
    props.reverseLayout
      ? '"image image image text text text text"'
      : '"text text text text image image image"'};

  ${Breakpoints.xLarge} {
    padding-top: 80px;
  }

  ${Breakpoints.large} {
    padding-top: 40px;
  }

  ${Breakpoints.medium} {
    padding-top: 40px;
  }

  ${Breakpoints.small} {
    padding-top: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'text';
  }
`

const Info = styled.div`
  grid-area: text;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding-left: 50px;

  ${Breakpoints.small} {
    align-items: center;
    padding-left: 0;
  }
`

const Heading = styled(Header)`
  text-align: start;
  margin-bottom: 35px;
  ${Breakpoints.small} {
    text-align: center;
  }
`

const Body = styled(Text)`
  text-align: start;
  margin-bottom: 35px;

  ${Breakpoints.small} {
    text-align: center;
  }
`

const ImageWrapper = styled.div`
  grid-area: image;
  max-width: 407px;
  max-height: 611px;
  border-radius: 203.5px;
  overflow: hidden;
`

const StyledImage = styled(Image)`
  max-width: ${props => props.maxWidth ?? 'inherit'};
  margin-left: auto;
  margin-right: auto;
`

export const TextAndImageCard = ({
  headerText,
  text,
  imagePath,
  buttonLink,
  buttonText,
  reverseLayout,
  maxImageWidth,
}) => {
  return (
    <Layout reverseLayout={reverseLayout}>
      <Info>
        <Heading variant='heading1'>{headerText}</Heading>
        <Body variant='large'>{text}</Body>
        <Link to={buttonLink}>
          <Button>
            <Text font='Roboto' variant='medium'>
              <Bold>{buttonText}</Bold>
            </Text>
          </Button>
        </Link>
      </Info>
      <ImageWrapper>
        <StyledImage src={imagePath} maxWidth={maxImageWidth} />
      </ImageWrapper>
    </Layout>
  )
}

TextAndImageCard.propTypes = {
  headerText: PropTypes.string,
  text: PropTypes.string,
  buttonLink: PropTypes.string,
  imagePath: PropTypes.string,
  buttonText: PropTypes.string,
  reverseLayout: PropTypes.bool,
  maxImageWidth: PropTypes.string,
}
