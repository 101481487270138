import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { Header } from '../../atoms/Typography/Header'
import { Text } from '../../atoms/Typography/Text'
import { Button } from '../../molecules/Button/Button'
import { SectionWrapper } from '../../molecules/SectionWrapper/SectionWrapper'
import { Colors } from '../../styles/colors'
import { Bold } from '../../styles/common'
import { Spacings } from '../../styles/spacings'

const AnchorWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const StyledAnchor = styled(Link)`
  margin-top: ${Spacings['32']};
`

export const HeaderAndText = ({
  headerText,
  contentText,
  buttonText,
  buttonLink,
}) => {
  return (
    <SectionWrapper
      backgroundColor={Colors.background}
      paddingTop
      paddingBottom
    >
      <Header variant='heading2'>{headerText}</Header>
      <Text variant='large'>{contentText}</Text>
      <AnchorWrapper>
        <StyledAnchor to={buttonLink}>
          <Button>
            <Text font='Roboto' variant='medium'>
              <Bold>{buttonText}</Bold>
            </Text>
          </Button>
        </StyledAnchor>
      </AnchorWrapper>
    </SectionWrapper>
  )
}

HeaderAndText.propTypes = {
  headerText: PropTypes.string,
  contentText: PropTypes.node,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
}
