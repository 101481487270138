import React from 'react'

import SEO from '../components/seo'
import Main from '../layouts/main'
import { InfoCards } from '../molecules/InfoCards/InfoCards'
import { SectionWrapper } from '../molecules/SectionWrapper/SectionWrapper'
import { HeaderAndText } from '../organisms/HeaderAndText/HeaderAndText'
// import { Reviews } from '../organisms/Reviews/Reviews'
import { TextAndImageCard } from '../organisms/TextAndImageCard/TextAndImageCard'

const IndexPage = () => (
  <Main>
    <SEO title='Poradnia Rehabilitacyjna Azory' />
    <SectionWrapper paddingBottom>
      <TextAndImageCard
        reverseLayout
        headerText='Odzyskaj swoją sprawność'
        text='Skorzystaj z zabiegów fizjoterapeutycznych, które dają rzeczywiste rezultaty.'
        imagePath='massage.jpg'
        buttonLink='/kontakt'
        buttonText={'Kontakt'}
      />
    </SectionWrapper>
    <InfoCards />
    {/*<Reviews />*/}
    <HeaderAndText
      headerText='O nas'
      buttonText='Więcej'
      contentText={
        <>
          Nasza placówka działa od 2001 roku. Od zawsze naszym priorytetem była terapia najbardziej dopasowana do
          pacjenta. Nasze leczenie opiera się na kompleksowej opiece pacjenta w zakresie rehabilitacji. Diagnoza
          lekarska specjalisty rehabilitacji lek. med. Jarosława Bal oraz skuteczne metody fizjoterapeutyczne stosowane
          przez zespół terapeutów oparte są na fachowej wiedzy, doświadczeniu i zaangażowaniu w wykonywaną pracę.
        </>
      }
      buttonLink='/oNas'
    />
  </Main>
)

export default IndexPage
