import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { Paper } from '../../atoms/Paper/Paper'
import { Text } from '../../atoms/Typography/Text'
import { Breakpoints } from '../../styles/breakpoints'
import { Colors } from '../../styles/colors'
import { Bold } from '../../styles/common'
import { Spacings } from '../../styles/spacings'
import ArrowIcon from '../../svg/arrow.svg'
import CalendarIcon from '../../svg/calendar.svg'
import PaperIcon from '../../svg/paper.svg'
import { SectionWrapper } from '../SectionWrapper/SectionWrapper'

const Layout = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridTemplateRows: 'auto',
  gridColumnGap: '30px',

  [Breakpoints.small]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(2, 1fr)',
    gridRowGap: '30px',
  },
})

const HeadingSpace = styled.div`
  margin-bottom: ${Spacings['22']};
`

const BlackText = styled(Text)`
  color: ${Colors.black};
`

const GreenText = styled(Text)`
  color: ${Colors.secondary};
  padding-right: ${Spacings['12']};
`

const StyledCalendarIcon = styled(CalendarIcon)`
  max-height: 67px;
  max-width: 67px;
  margin-bottom: ${Spacings['22']};
`

const StyledPaperIcon = styled(PaperIcon)`
  max-height: 67px;
  max-width: 67px;
  margin-bottom: ${Spacings['22']};
`

const AnchorWidth = styled.div`
  width: fit-content;
`

const SeeMoreAnchor = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`

const SeeMoreTextWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: ${Spacings['22']};
`

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`
export const InfoCards = () => {
  return (
    <SectionWrapper
      background={`linear-gradient(0deg, ${Colors.lighterGrey} 65%, ${Colors.background} 65%);`}
      paddingTop
      paddingBottom
    >
      <Layout>
        <Paper>
          <FlexWrapper>
            <div>
              <StyledPaperIcon />
              <HeadingSpace>
                <BlackText font='Roboto' variant='xLarge'>
                  <Bold>Zabiegi Refundowane</Bold>
                </BlackText>
              </HeadingSpace>
              <Text variant='large'>
                Wykonujemy zabiegi w ramach Narodowego Funduszu Zdrowia, aby pomóc każdemu, kto potrzebuje
                profesjonalnej opieki fizjoterapeutycznej.
              </Text>
            </div>
            <AnchorWidth>
              <SeeMoreAnchor to='/zabiegiRefundowane'>
                <SeeMoreTextWrapper>
                  <GreenText font='Roboto' variant='large'>
                    <Bold>Więcej</Bold>
                  </GreenText>
                  <ArrowIcon />
                </SeeMoreTextWrapper>
              </SeeMoreAnchor>
            </AnchorWidth>
          </FlexWrapper>
        </Paper>
        <Paper>
          <FlexWrapper>
            <div>
              <StyledCalendarIcon />
              <HeadingSpace>
                <BlackText font='Roboto' variant='xLarge'>
                  <Bold>Wizyty Prywatne</Bold>
                </BlackText>
              </HeadingSpace>
              <Text variant='large'>
                Wybierz dogodny termin dla siebie, a my zajmiemy się resztą. Stosujemy skuteczne metody terapii.
              </Text>
            </div>
            <AnchorWidth>
              <SeeMoreAnchor to='/wizytyPrywatne'>
                <SeeMoreTextWrapper>
                  <GreenText font='Roboto' variant='large'>
                    <Bold>Więcej</Bold>
                  </GreenText>
                  <ArrowIcon />
                </SeeMoreTextWrapper>
              </SeeMoreAnchor>
            </AnchorWidth>
          </FlexWrapper>
        </Paper>
      </Layout>
    </SectionWrapper>
  )
}
